import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserGuard } from './oauth/guards/user-guard.service';
import { BranchGuard } from './oauth/guards/branch-guard.service';
import { BadgeGuard } from './oauth/guards/badge-guard.service';
import { BlogGuard } from './oauth/guards/blog-guard.service';
import { GroupGuard } from './oauth/guards/group-guard.service';
import { SuggestionGuard } from './oauth/guards/suggestion-guard.service';
import { DataAggregationGuard } from './oauth/guards/data-aggregation-guard.service';
import { McdGuard } from './oauth/guards/mcd-guard.service';
import { OAuthService } from './oauth/oauth.service';
import { PageNotFoundComponent } from './404/page-not-found.component';
import { ProfileGuard } from './oauth/guards/profile-guard.service.';

export const authProviders = [UserGuard, ProfileGuard, BranchGuard, BadgeGuard, BlogGuard, GroupGuard,
  SuggestionGuard, DataAggregationGuard, McdGuard, OAuthService];

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [UserGuard],
    data: { background: true }
  },
  {
    path: 'account',
    children: [
      {
        path: 'settings',
        children: [
          {
            path: 'business-user',
            loadChildren: () => import('./accounts/settings/business-user/business-user-setting.module').then(m => m.BusinessUserSettingModule),
            canActivate: [UserGuard]
          },
          {
            path: 'company',
            loadChildren: () => import('./accounts/settings/company/company-setting.module').then(m => m.CompanySettingModule),
            canActivate: [UserGuard]
          },
        ]
      },
      {
        path: 'registrations',
        children: [
          {
            path: 'business-user',
            loadChildren: () => import('./accounts/registrations/business-user/business-user-registration.module').then(m => m.BusinessUserRegistrationModule),
            data: { navbar: false, background: false },
            canActivate: [UserGuard]
          },
          {
            path: 'company',
            loadChildren: () => import('./accounts/registrations/company/company-registration.module').then(m => m.CompanyRegistrationModule),
            data: { navbar: false, background: false },
            canActivate: [UserGuard]
          },
          {
            path: 'second-company',
            loadChildren: () => import('./accounts/registrations/second-company/second-company-registration.module').then(m => m.SecondCompanyRegistrationModule),
            canActivate: [UserGuard]
          },
        ]
      },
      {
        path: 'sign-in',
        loadChildren: () => import('./oauth/sign-in/sign-in.module').then(m => m.SignInModule),
        data: { background: true, navbar: false }
      },
      {
        path: 'sign-up',
        loadChildren: () => import('./oauth/sign-up/sign-up.module').then(m => m.SignUpModule),
        data: { background: true, navbar: false }
      },
      {
        path: 'sign-out',
        loadChildren: () => import('./oauth/sign-out/sign-out.module').then(m => m.SignOutModule),
        data: { background: true, navbar: false }
      },
      {
        path: 'request-password',
        loadChildren: () => import('./accounts/request-password/request-password.module').then(m => m.RequestPasswordModule),
        data: { background: true, navbar: false }
      },
      {
        path: 'confirm',
        loadChildren: () => import('./oauth/confirm/confirm.module').then(m => m.ConfirmModule),
        data: { background: true, navbar: false }
      },
    ]
  },
  {
    path: 'notifications',
    loadChildren: () => import('./notifications/notification-page.module').then(m => m.NotificationPageModule),
    canActivate: [UserGuard]
  },
  {
    path: 'me',
    loadChildren: () => import('./me/me-dashboard/me-dashboard.module').then(m => m.MeDashboardModule),
    canActivate: [UserGuard],
    data: { background: true }
  },
  {
    path: 'profile',
    loadChildren: () => import('./services/company-profile/business-view/company-profile.module').then(m => m.CompanyProfileModule),
    canActivate: [ProfileGuard]
  },
  {
    path: 'v/profile/:slug',
    loadChildren: () => import('./services/company-profile/consumer-view/v-company-profile.module').then(m => m.VCompanyProfileModule),
  },
  {
    path: 'badge',
    loadChildren: () => import('./services/badge/business-view/badge.module').then(m => m.BadgeModule),
    canActivate: [BadgeGuard]
  },
  {
    path: 'badge/:id',
    loadChildren: () => import('./services/badge/consumer-view/badge-client.module').then(m => m.BadgeClientModule),
    data: { background: false }
  },
  {
    path: 'data-aggregation',
    loadChildren: () => import('./services/data-aggregation/data-aggregation.module').then(m => m.DataAggregationModule),
    canActivate: [DataAggregationGuard]
  },
  {
    path: 'data-2',
    loadChildren: () => import('./services/data-2/data-2.module').then(m => m.Data2Module),
    canActivate: [DataAggregationGuard]
  },
  {
    path: 'blog',
    loadChildren: () => import('./services/blog/blog.module').then(m => m.BlogModule),
    canActivate: [BlogGuard]
  },
  {
    path: 'admin-groups',
    loadChildren: () => import('./services/admin-group/admin-groups.module').then(m => m.AdminGroupsModule),
    canActivate: [GroupGuard]
  },
  {
    path: 'groups',
    loadChildren: () => import('./services/my-group/groups.module').then(m => m.GroupsModule),
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule),
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  // imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  providers: [authProviders]
})
export class AppRoutingModule { }
