/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 9/28/16.
 */
import { FileUploader } from 'ng2-file-upload';
import { Cookie } from '../../../../constants/storage';
import { StorageService } from '../../../oauth/storage.service';

/**
 * GLOSUSFileUploader
 *
 * extends the file uploader from https://github.com/valor-software/ng2-file-upload
 *
 */
export class GLOSUSFileUploader extends FileUploader {

    public static getMimeType(fileType: string) {
        switch (fileType) {
            case 'image':
                return ['image/jpeg', 'image/png'];
            case 'pdf':
                return ['application/pdf'];
            case 'attachment':
                return ['application/pdf',
                    'text/csv',
                    'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'image/jpeg', 'image/png',
                    'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                    'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                    'application/vnd.openxmlformats-officedocument.presentationml.slideshow'
                ];
        }

        return ['*'];
    }

    constructor(fileSize: number, fileType: string, service: StorageService) {
        super({
            // TODO: disable for now, csv or xls is not supported by mime type check
            // allowedMimeType: GLOSUSFileUploader.getMimeType(fileType),
            maxFileSize: fileSize  // default 5MB
        });
        // add auth token
        this.authToken = 'Bearer ' + service.get(Cookie.accessToken);
    }

    /** Upload single file  */
    public uploadSingleFile(url: string) {
        console.log("UPLOADING FILE", url);

        const file = this.queue[0];
        file.url = url;
        file.upload();
    }
}
