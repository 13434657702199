/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 6/23/16.
 */
import { Injectable } from '@angular/core';
import { Router, Params } from '@angular/router';

import { User } from '../../models/user';
import { Observable, Subject } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';

import {
    TOKEN_API_URL, USER_CLIENT_ID, USER_CLIENT_SECRET, USER_API_BASE_URL,
    ANONYMOUS_CLIENT_ID, ANONYMOUS_CLIENT_SECRET
} from '../../constants/url';
import { ServerUtil } from '../../utils/server-util';
import { OAuthUtil } from '../../utils/oauth-util';

import { TranslateService } from '@ngx-translate/core';
import { Tag } from '../shared/input/tag-editor/tag';
import { Cookie } from '../../constants/storage';
import { StorageService } from './storage.service';
import { ValueUtil } from '../../utils/value-util';
import { StringUtil } from '../../utils/string-util';
import { BACKGROUNDS } from '../../constants/backgrounds';
import { map, share, tap, catchError } from 'rxjs/operators';

/** Shared service for oauth2 */
@Injectable()
export class OAuthService {
    isLoggedIn = false;
    redirectUrl: string = null;
    queryParams: Params = null;

    // current logged in user in memory
    private _user: User;
    private _user$: Subject<User>;
    // observable of get user brief
    private _getBriefObservable: Observable<any> = null;
    // observable of request anonymous token
    private _requestTokenObservable: Observable<any> = null;

    constructor(private _http: HttpClient,
        private _router: Router,
        private _translate: TranslateService,
        private _storageService: StorageService) {
        this._user = null;
        this._user$ = <Subject<User>>new Subject();
    }

    get user$() {
        return this._user$.asObservable();
    }

    getLoggedInUser() {
        // console.log("Current Logged in user: ", this._user);

        return this._user;
    }

    getSlug() {
        if (ValueUtil.isEmpty(this._user) || ValueUtil.isEmpty(this._user.lastLoginCompany)) {
            return null;
        }
        return this._user.lastLoginCompany.slug;
    }

    /** Get observable of get user brief */
    getUserBrief() {
        if (ValueUtil.isEmpty(this._getBriefObservable)) {
            this._getBriefObservable = this.doGetUserBrief().pipe(share());
        }
        return this._getBriefObservable;
    }

    /** Log out from app */
    logout() {
        this.isLoggedIn = false;
        this.redirectUrl = null;
        this._user = null;
        this._user$.next(null);
        this._storageService.clear();
        this._router.navigate(['/account/sign-in']);
    }

    setUser(user: User) {
        this._user$.next(user);
        this._user = user;
        this.isLoggedIn = true;
    }

    /** Log in and fetch access token via api */
    login(username: string, password: string) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        const body = JSON.stringify({
            grant_type: 'password',
            client_id: USER_CLIENT_ID,
            client_secret: USER_CLIENT_SECRET,
            username: username,
            password: password
        });
        console.log("LOGGIN IN ", body);

        const options = { headers: headers };
        return this._http.post(TOKEN_API_URL, body, options);
    }

    /** Update logged in business user last login company via api, and notify changes to all current user subscriber */
    updateUserLastLoginCompany(companySlug: string) {
        const body = JSON.stringify({ new_company: companySlug });
        return this._http.put(USER_API_BASE_URL + '/last_login_company', body).pipe(
            map(res => ServerUtil.handleServerResponse(res).data),
            tap(res => {
                if (ValueUtil.isEmpty(res.user)) {
                    alert(res);
                } else {
                    const user = new User();
                    user.generateBusinessUserBriefFromJson(res.user);
                    this.setUser(user);
                }
            }),
            catchError(ServerUtil.handleOtherError)
        );
    }

    /** Get observable of request anonymous token */
    getAnonymousToken() {
        if (ValueUtil.isEmpty(this._requestTokenObservable)) {
            this._requestTokenObservable = this.requestAnonymousToken().pipe(share());
        }
        return this._requestTokenObservable;
    }

    checkLoggedInUserProfileStatus(user: User) {
        if (ValueUtil.isEmpty(user) || ValueUtil.isEmpty(user.id)) {
            this.logout();
            return false;
        }

        // check redirect
        if (!user.isConsumer) {
            // business user
            if (!user.finishCompany) {
                this.redirectToCompanyRegistration();
                return false;
            }
            if (!user.finishUser) {
                this.redirectToUserRegistration(user);
                return false;
            }
        } else {
            if (!user.finishUser) {
                this.redirectToUserRegistration(user);
                return false;
            }
        }
        if (user.lastReset) {
            this.redirectToUpdatePassword();
            return false;
        }

        return true;
    }

    redirectToUpdatePassword() {
        this._router.navigate(['/account/settings/business-user']);
        return;
    }

    redirectToDashboard(user: User) {
        if (StringUtil.isEmpty(this.redirectUrl)) {
            if (!ValueUtil.isEmpty(user.lastLoginCompany)) {
                this._router.navigate(['/dashboard']);
            } else {
                this._router.navigate(['/me']);
            }
        } else {
            this._router.navigate([this.redirectUrl], { queryParams: this.queryParams });
        }
    }

    redirectToCompanyRegistration() {
        this._router.navigate(['/account/registrations/company']);
        return;
    }

    redirectToUserRegistration(user: User) {
        if (user.isConsumer) {
            this._router.navigate(['/account/registrations/consumer']);
        } else {
            this._router.navigate(['/account/registrations/business-user']);
        }
        return;
    }

    mergeTags(tags: Tag[]) {
        if (null === this._user || null === this._user.lastLoginCompany) {
            return;
        }
        const allTags = [];
        for (const i in tags) {
            if (tags.hasOwnProperty(i)) {
                let shared = false;
                for (const j in this._user.lastLoginCompany.tags) {
                    if (this._user.lastLoginCompany.tags.hasOwnProperty(j)) {
                        if (this._user.lastLoginCompany.tags[j].name === tags[i].name) {
                            shared = true;
                            break;
                        }
                    }
                }
                if (!shared) {
                    allTags.push(tags[i]);
                }
            }
        }
        this._user.lastLoginCompany.tags = allTags.concat(this._user.lastLoginCompany.tags);
        this.setUser(this._user);
    }

    getUserLastResetPassword() {
        return this._http.get(USER_API_BASE_URL + 'last_reset').pipe(
            map(res => ServerUtil.handleServerResponse(res).data.data),
            catchError(error => {
                return ServerUtil.handleOtherError(error);
            })
        );
    }

    /** Request anonymous token via api */
    private requestAnonymousToken(): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const body = JSON.stringify({
            grant_type: 'client_credentials',
            client_id: ANONYMOUS_CLIENT_ID,
            client_secret: ANONYMOUS_CLIENT_SECRET
        });
        const options = { headers: headers };
        return this._http.post(TOKEN_API_URL, body, options).pipe(
            tap(res => {
                OAuthUtil.saveAnonymousAccessToken(res, this._storageService);
                this._requestTokenObservable = null;
            })
        );
    }

    /** Trigger get user brief via API */
    private doGetUserBrief() {
        // fetch logged in user info
        return this._http.get(USER_API_BASE_URL + '/brief').pipe(
            map(res => {

                const user = new User();
                user.generateBusinessUserBriefFromJson(ServerUtil.handleServerResponse(res).data.user);
                // set locale
                if (user.locale) {
                    this._translate.use(user.locale);
                    this._storageService.set(Cookie.locale, user.locale);
                } else {
                    this._translate.use('en');
                    this._storageService.set(Cookie.locale, 'en');
                }
                // set background
                if (user.background_id) {
                    window.document.body.style.backgroundColor = BACKGROUNDS[user.background_id].value;
                    window.document.documentElement.style.backgroundColor = BACKGROUNDS[user.background_id].value;
                }
                else {

                    window.document.body.style.backgroundColor = BACKGROUNDS[1].value;
                    window.document.documentElement.style.backgroundColor = BACKGROUNDS[1].value;
                }
                this._getBriefObservable = null;
                this.setUser(user);

                return user;
            }),
            catchError(error => {
                console.log(error.message);


                this.logout();
                this._getBriefObservable = null;
                return ServerUtil.handleOtherError(error);
            })
        );
    }
}
