/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 6/23/16.
 *
 * Company Model
 *
 */
import { Tag } from '../app/shared/input/tag-editor/tag';
import { ValueUtil } from '../utils/value-util';

export class Company {
    id: number;
    slug: string;
    name: string;
    legalForm: string;
    industry: number = null;
    country: string = null;
    level: number = null;
    type: number = null;
    employees: number = null;
    logo: string;
    banner: string;
    postcode: string;
    city: string;
    rate: number;
    published = false;
    profilePublished = false;
    structurePublished = false;
    managementPublished = false;
    responsibilityLinePublished = false;
    modules: number[];
    tags: Tag[];
    followers: number;

    /** Generate company relation instance via json */
    generateCompanyRelation(json: any) {
        this.slug = json.slug;
        this.name = json.name;
        this.legalForm = json.legalForm;
        this.logo = json.logo;
    }

    /** Generate company brief instance via json */
    generateCompanyBrief(json: any) {
        // console.log("Company brief", json);
        this.id = json.id;
        this.slug = json.slug;
        this.name = json.name;
        this.legalForm = json.legalForm;
        this.logo = json.logo;
        this.banner = json.banner;
        this.published = json.published;
        this.profilePublished = json.profilePublished;
        this.structurePublished = json.structurePublished;
        this.managementPublished = json.managementPublished;
        this.responsibilityLinePublished = json.responsibilityLinePublished;
        this.modules = json.modules;
        this.industry = json.industry;
        this.country = json.country;
        this.city = json.city;
        this.followers = json.followers_count;

        const tags: Tag[] = [];
        if (null !== json.tags && undefined !== json.tags) {
            for (const tagJson of json.tags) {
                if (null !== tagJson) {
                    const tag = new Tag;
                    tag.generateTagFromJson(tagJson);
                    tags.push(tag);
                }
            }
        }
        this.tags = tags;

    }

    /** Generate company list instance via json */
    generateCompanyList(json: any) {
        this.id = json.id;
        this.slug = json.slug;
        this.name = json.name;
        this.legalForm = json.legalForm;
        this.logo = json.logo;
        this.country = json.country;
        this.city = json.city;
        this.industry = json.industry;
    }

    /** Generate company setting data */
    generateCompanySetting(json: any) {
        this.id = json.id;
        this.slug = json.slug;
        this.name = json.name;
        this.legalForm = json.legalForm;
        this.country = json.country;
        this.industry = json.industry;
        this.city = json.city;
        this.postcode = json.postcode;
        this.level = json.level;
        this.type = json.type;
    }

    /** Get legal entity */
    get legalEntity(): string {
        if (!ValueUtil.isEmpty(this.name) && !ValueUtil.isEmpty(this.legalForm)) {
            return this.name + ' ' + this.legalForm;
        }
        if (!ValueUtil.isEmpty(this.name)) {
            return this.name;
        }
        return '';
    }

    get subscribeBadge(): boolean {
        return true
        return this.modules.indexOf(1) >= 0;
    }

    get subscribeGroup(): boolean {
        return true
        return this.modules.indexOf(2) >= 0;
    }

    get subscribeSuggestion(): boolean {
        return true
        return this.modules.indexOf(3) >= 0;
    }

    get subscribeBranch(): boolean {
        return true
        return this.modules.indexOf(4) >= 0;
    }

    get subscribeDataAggregation(): boolean {
        return true
        return this.modules.indexOf(5) >= 0;
    }

    get subscribeBlog(): boolean {
        return true
        return this.modules.indexOf(6) >= 0;
    }

    get subscribeMcd(): boolean {
        return true
        return this.modules.indexOf(7) >= 0;
    }

    get subscribeProfile(): boolean {
        return true
        return this.modules.indexOf(8) >= 0;
    }

}
