/**
 * Created by Dansen Zhou <dansen.zhou@glosus.com> on 6/23/16.
 *
 * User Model
 */
import { Company } from './company';
import { BusinessUserRole } from './relation';
import { Interest } from './interest';
import { BUSINESS_INTERESTS } from '../constants/interests';
import { ValueUtil } from '../utils/value-util';

export class User {
    id: string;
    photo: string;
    photo200: string;
    email: string;
    secondaryEmail: string;
    firstName: string;
    lastName: string;
    isConsumer: boolean;
    lastLoginCompany: Company = null;
    lastLoginCompanyRole: number;
    gender: number = null;
    title = 0;
    jobPosition: string;
    bio: string;
    dob: string;
    city: string;
    country: string = null;
    level: number = null;
    locale: string;
    interests: Interest[];
    companyList: Company[];
    finishCompany: boolean;
    finishUser: boolean;
    lastReset: boolean;
    follows: Company[];
    background_id: number;
    status: number;

    /** Generate business user profile instance from json */
    generateBusinessUserProfileFromJson(json: any) {
        this.id = json.id;
        this.photo200 = json.photo200;
        this.email = json.email;
        this.secondaryEmail = json.secondaryEmail;
        this.firstName = json.firstName;
        this.lastName = json.lastName;
        this.isConsumer = json.isConsumer;
        this.gender = json.gender;
        this.title = null === json.title ? 0 : json.title;
        this.jobPosition = json.jobPosition;
        this.bio = json.bio;
        this.dob = json.dob ? json.dob : '';
        this.city = json.city;
        this.country = json.country;
        this.level = json.level;
        this.locale = json.locale;
        const interests: Interest[] = [];
        if (json.interests)
            for (const interest of BUSINESS_INTERESTS) {
                const userInterest = new Interest();
                userInterest.id = interest.key;
                console.log(interest);

                if (interest.key in json.interests) {
                    userInterest.state = true;
                }
                interests.push(userInterest);
            }
        this.interests = interests;
    }

    /** Generate business user brief instance from json */
    generateBusinessUserBriefFromJson(json: any) {
        this.id = json.id;
        this.photo = json.photo;
        this.photo200 = json.photo200;
        this.email = json.email;
        this.secondaryEmail = json.secondaryEmail;
        this.firstName = json.firstName;
        this.lastName = json.lastName;
        this.isConsumer = json.isConsumer;
        this.locale = json.locale;
        this.finishCompany = json.finishCompany;
        this.finishUser = json.finishUser;
        this.lastReset = null === json.last_reset ? false : json.last_reset;
        this.background_id = json.background_id;

        if (!ValueUtil.isEmpty(json.lastLoginCompany)) {
            const company = new Company();
            company.generateCompanyBrief(json.lastLoginCompany);
            this.lastLoginCompany = company;
        }

        this.lastLoginCompanyRole = json.lastLoginCompanyRole;

        const companyList: Company[] = [];
        if (!ValueUtil.isEmpty(json.companyList)) {
            for (const companyJson of json.companyList) {
                if (!ValueUtil.isEmpty(companyJson)) {
                    const com = new Company();
                    com.generateCompanyBrief(companyJson);
                    companyList.push(com);
                }
            }
        }
        this.companyList = companyList;

        const follows: Company[] = [];
        if (!ValueUtil.isEmpty(json.follows)) {
            for (const companyJson of json.follows) {
                if (!ValueUtil.isEmpty(companyJson)) {
                    const com = new Company();
                    com.generateCompanyBrief(companyJson);
                    follows.push(com);
                }
            }
        }
        this.follows = follows;
    }

    /** Generate business user list instance from Json */
    generateBusinessUserListFromJson(json: any) {
        this.id = json.id;
        this.photo = json.photo;
        this.email = json.email;
        this.firstName = json.firstName;
        this.lastName = json.lastName;
        this.jobPosition = json.jobPosition;
        this.title = json.title;
        this.status = json.status ? json.status : 1;
    }

    get isPending(): boolean {
        return this.status === 0;
    }

    get isApproved(): boolean {
        return this.status === 1;
    }

    /** Check if user is following company */
    isFollowing(companySlug: string): boolean {
        return this.follows.map(company => company.slug).indexOf(companySlug) > -1;
    }

    /** Check if user is admin */
    get isAdmin(): boolean {
        return this.lastLoginCompanyRole === BusinessUserRole.ROLE_ADMIN;
    }

    /** Check if user is editor */
    get isEditor(): boolean {
        return this.lastLoginCompanyRole <= BusinessUserRole.ROLE_EDITOR;
    }

    /** Check if user is assistant */
    get isAssistant(): boolean {
        return this.lastLoginCompanyRole <= BusinessUserRole.ROLE_ASSISTANT;
    }

    /** Check if user is viewer */
    get isViewer(): boolean {
        return this.lastLoginCompanyRole <= BusinessUserRole.ROLE_VIEWER;
    }

    /** Check if user is auditor */
    get isAuditor(): boolean {
        return this.lastLoginCompanyRole === BusinessUserRole.ROLE_AUDITOR;
    }

    get isFinished(): boolean {
        if (this.isConsumer) {
            if (this.finishUser) {
                return true;
            }
        } else {
            if (this.finishCompany && this.finishUser) {
                return true;
            }
        }
        return false;
    }

    get fullName(): string {
        return this.firstName + ' ' + this.lastName;
    }

    get shortCut(): string {
        return this.firstName.substr(0, 1).toUpperCase() + this.lastName.substr(0, 1).toUpperCase();
    }

    generateDefaultInterests() {
        const interests: Interest[] = [];
        for (const interest of BUSINESS_INTERESTS) {
            const userInterest = new Interest();
            userInterest.id = interest.key;
            interests.push(userInterest);
        }
        this.interests = interests;
    }
}
