import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookieService, CookieModule } from 'ngx-cookie';
import { StorageService, BrowserStorageService } from './oauth/storage.service';
import { OAuthService } from './oauth/oauth.service';
import { FlashMessageModule } from './shared/views/flash-message/flash-message.module';
import { CookiesBannerModule } from './shared/views/cookie-banner/cookie-banner.module';
import { LoadingModule } from './shared/basis/loading/loading.module';
import { BackgroundModule } from './shared/views/background/background.module';
import { NavbarModule } from './navbar/navbar.module';
import { PageNotFoundModule } from './404/page-not-found.module';
import { RefreshTokenInterceptor } from './oauth/refresh-token.interceptor';
import { TokenInterceptor } from './oauth/token.interceptor';
import { FormsModule } from '@angular/forms';
// Where to load the translation files from
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    // Used in Production
    // TranslateModule.forRoot(),

    // Used in Development
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),

    LoadingModule,
    NavbarModule,
    BackgroundModule,
    PageNotFoundModule,
    FlashMessageModule,
    CookiesBannerModule,
    CookieModule.forRoot(),
  ],
  providers: [
    CookieService,
    OAuthService,
    {
      provide: StorageService, useClass: BrowserStorageService, deps: [CookieService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor, // RefreshTokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
